import Vue from "vue";

const alwaysIncludedDevices = Vue.component('always-included-devices', {
    computed: {
        doorSensorImage: function () {
            return JSENV.assets_url + "/images/starter-kit-ai.png";
        },
        controlPanelImage: function () {
            return JSENV.assets_url + "/images/control-panel-ai.png";
        }
    },
    template: `
        <div class="w-11/12 rounded shadow-lg p-5 font-quicksand">
            <h4>Devices always included in this plan</h4>
            <p>These devices come included in the plan you selected, so we´ve automatically added them to your cart.</p>
            <div class="flex flex-col justify-center items-center md:flex-row md:justify-start md:items-start mt-2 md:space-x-2">
                <div class="w-40">
                    <img :src="doorSensorImage">
                    <h5>Security Starter Kit</h5>
                    <p class="text-xs">2 Door/Window Sensors & 1 Motion Sensor</p>
                </div>
                <div class="w-40">
                    <img :src="controlPanelImage">
                    <h5 class="text-center">Control Panel</h5>
                </div>

            </div>
        </div>
    `
});

export default alwaysIncludedDevices;
