import Vue from "vue";
import {mapGetters} from "vuex";

const installationOptions = Vue.component('installation-options', {
    computed: {
        ...mapGetters([
            'getSelectedRegion',
            'getSelfInstallationValue',
            'getApplicationTexts',
            'getPlanDefaultInstallationOption',
            'getInstallationCharges'
        ]),
        texts: function () {
            return this.getApplicationTexts;
        },
        defaultInstallation: function () {
            return this.getPlanDefaultInstallationOption;
        }
    },
    methods: {
        onInstallationPlanChange: async function (e) {
            await this.$store.dispatch('changeInstallationPlan', e.target.value);
            await this.$store.dispatch('reCalculateTotalPrice');
        }
    },
    updated() {
        if ( this.defaultInstallation === 'self_install') {
                this.$store.dispatch('changeInstallationPlan', 'self_install');
                this.$store.dispatch('reCalculateTotalPrice');
            } else if ( this.defaultInstallation === 'professional_install' ) {
                this.$store.dispatch('changeInstallationPlan','professional_install');
                this.$store.dispatch('reCalculateTotalPrice');
            }
    },
    template:`
    <div class="planInstallation" v-on:change="this.onInstallationPlanChange">
                <h2 class="text-3xl font-quicksand font-thin text-primary">Installation</h2>
                <div v-if="getSelfInstallationValue" class="md:flex mt-5">
                    <div style="flex-basis: 25%">
                        <label class="customRadioControl">
                            Self Install
                            <input type="radio" name="installationplan-radio" value="self_install"
                                   :checked="defaultInstallation === 'self_install'">
                            <span class="radioChecked"></span>
                        </label>
                        <p class="text-4xl font-thin pl-8"><sup>{{ this.getSelectedRegion.region_currency }}</sup>0</p>
                    </div>
                    <div class="space-y-5 font-quicksand" style="flex-basis: 50%">
                        <p class="font-bold">{{ texts.self_install_title }}</p>
                        <p>{{ texts.self_install_description }}</p>
                    </div>
                </div>

                <div class="md:flex mt-5">
                    <div style="flex-basis: 25%">
                        <label class="customRadioControl">
                            In-Person Install
                            <input type="radio" name="installationplan-radio" value="professional_install"
                                   :checked="defaultInstallation === 'professional_install'">
                            <span class="radioChecked"></span>
                        </label>
                        <div class="text-4xl font-thin pl-8">
                            <p class="text-xs">One-time payment</p>
                            <sup>{{ this.getSelectedRegion.region_currency }}</sup>{{getInstallationCharges}}
                        </div>
                    </div>
                    <div class="space-y-5 font-quicksand" style="flex-basis: 50%">
                        <p class="font-bold">{{ texts.pro_install_title }}</p>
                        <p>{{ texts.pro_install_description }}</p>
                    </div>
                </div>
            </div>
    `
});

export default installationOptions;
