import Vue from "vue";
import { mapGetters } from 'vuex'

const cameraComponent = Vue.component('camera-component', {
    props: [
        'cameraDetails',
        'planId',
        'maxIncluded'
    ],
    mounted: function () {
        console.log('camera component mounted', this.cameraDetails.id);
    },
    computed: {
        ...mapGetters(['getSelectedTerm']),
        isIncludedInPlan: function() {
            return this.$store.getters.isCameraIncludedInHomeAutomation(this.cameraId);
        },
        availableFreeDevices: function () {
            return this.maxIncluded - this.selectedQuantity
        },
        selectedRegion: function () {
            return this.$store.getters.getSelectedRegion;
        },
        selectedPaymentOption: function () {
            return this.$store.getters.getSelectedPaymentOption;
        },
        cameraId: function() { return this.cameraDetails.id },
        cameraImage: function () { return JSENV.assets_url + this.cameraDetails.device_image },
        cameraName: function () { return this.cameraDetails.device_name },
        cameraPrice: function() { return this.cameraDetails.device_price },
        cameraQuantity: function () { return this.cameraDetails.device_quantity },
        selectedQuantity: function () { return 0 }
    },
    methods: {
        onQuantityChange: async function (e) {
            await this.$store.dispatch('actionAddCamerasToPlan', {
                ...this.cameraDetails,
                selectedQuantity: parseInt(e.target.value)
            });
            await this.$store.dispatch('reCalculateTotalPrice');
        }
    },
    template: `
        <div class="bg-white p-2 shadow-lg rounded relative md:w-4/3 md:min-w-card font-quicksand border-2 border-transparent" :class="{'border-green-500' : isIncludedInPlan}">
            <div v-if="isIncludedInPlan" class="absolute text-sm bg-gray-500 text-white py-1 px-4 top-0 right-0">{{isIncludedInPlan}} Included</div>
            <div class="flex flex-col items-center h-full space-y-5">
                <div class="camera-image flex-1">
                    <img :src="cameraImage">
                </div>
                <div class="camera-description w-full flex-1">
                    <p class="text-xs font-bold">{{ cameraName }}</p>
                    <div class="text-xs font-bold text-purple-500">
                        <p v-if="this.selectedPaymentOption == 'monthly'">{{ this.selectedRegion.region_currency }}{{ cameraPrice[this.selectedRegion.region_id].monthly_price[getSelectedTerm] }}/month</p>
                        <p v-if="this.selectedPaymentOption == 'onetime'">{{ this.selectedRegion.region_currency }}{{ cameraPrice[this.selectedRegion.region_id].onetime_price[getSelectedTerm] }} one time payment</p>
                    </div>
                </div>
                <div class="camera-dropdown-control w-full mt-auto">
                    <select class="w-full border rounded focus:shadow-lg h-12" v-on:change="this.onQuantityChange">
                        <option v-for="(n, index) in cameraQuantity + 1 ">{{ index }}</option>
                    </select>
                </div>
            </div>
        </div>
    `
});

export default cameraComponent;