import Vue from "vue";
import {mapGetters} from 'vuex'

const regionSelection = Vue.component('region-selection', {
    computed: {
        ...mapGetters([
            "getRegions",
            "getSelectedRegion"
        ])
    },
    methods: {
        onRegionChange: async function (e) {
            await this.$store.dispatch('changeSelectedRegion', e.target.value);
            await this.$store.dispatch('reCalculateTotalPrice');
        }
    },
    template: `
            <div>
                <h2 class="text-3xl font-quicksand font-thin text-primary">Region</h2>
                <h5>Select your region to see prices in your locale.</h5>
                <select class="border rounded focus:shadow-lg h-12 px-2 mt-5" v-on:change="this.onRegionChange">
                    <option>Select your region</option>
                    <option v-for="region in this.getRegions"
                            :value="region.region_id"
                            :data-region="getSelectedRegion.region_id"
                            :selected="getSelectedRegion.region_id === region.region_id || region.default"
                    >{{ region.region_name }}</option>
                </select>
            </div>
    `
});

export default regionSelection;