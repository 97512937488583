import Vue from "vue";
import {mapGetters} from "vuex";

Vue.component('email-quote-modal', {
    props: ['actionType'],
    data() {
        return {
            firstName: '',
            lastName: '',
            emailAddress: '',
        }
    },
    computed: {
        ...mapGetters(['getIsEmailSending'])
    },
    methods: {
      handleOnSignUp: async function () {
            await this.$store.dispatch('sendSignUpEmail', {
                firstName: this.firstName,
                lastName: this.lastName,
                emailAddress: this.emailAddress,
                actionType: this.actionType
            });
      }
    },
    template:`<div class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
          <DialogTitle as="h3" class="text-lg leading-6 font-bold text-gray-900">
                    Thanks for your interest in TELUS SmartHome Security
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm">
                      Please fill out the form below to continue.
                    </p>
                  </div>
            <div class="mt-4">
            <div class="mb-4">
     <label class="block text-gray-700 text-sm font-bold mb-2">
       First Name
     </label>
     <input v-model="firstName" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="firstname" type="text" placeholder="First Name">
   </div>
   <div class="mb-4">
<label class="block text-gray-700 text-sm font-bold mb-2">
Last Name
</label>
<input v-model="lastName" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="lastname" type="text" placeholder="Last Name">
</div>
<div class="mb-4">
<label class="block text-gray-700 text-sm font-bold mb-2">
Email
</label>
<input v-model="emailAddress" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Last Name">
</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button @click="handleOnSignUp" :disabled="getIsEmailSending" type="button" class="bg-green w-full inline-flex justify-center rounded-md border border-transparent shadow-sm p-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm" @click="open = false">
              <span v-if="getIsEmailSending">Please Wait..</span>
              <span v-else>Send</span>
              </button>
              <button @click="$emit('close')" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm p-4 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="open = false" ref="cancelButtonRef">
                Cancel
              </button>
            </div>
    </div>
  </div>
</div>`
});

Vue.component('book-consultation-modal', {
    data() {
        return {
            name: '',
            email: ''
        }
    },
    methods: {
        handleBookConsultation: function () {
            window.open(`https://calendly.com/telussmarthome/consultation?name=${this.name}&email=${this.email}`);
        }
    },
    template:`<div class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <!-- Heroicon name: outline/exclamation -->
              <svg style="background: #7868e6;color: white;border-radius: 5px; padding: 5px;" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              Book a free consultation
            </h3>
            <div class="mt-2">
              <div class="text-black flex">
                <div class="font-quicksand">
                    <label>Your Name:</label>
                    <input v-model="name" type="text" class="border-2">
                </div>
                <div class="font-quicksand">
                    <label>Your Email:</label>
                    <input v-model="email" type="email" class="border-2">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button @click="handleBookConsultation" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
          Send
        </button>
        <button type="button" @click="$emit('close')" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>`
});

const actionButtonsComponent = Vue.component('action-buttons-component', {
    data() {
        return {
            showEmailQuoteModal: false,
            showBookConsultationModal: false,
            actionType: '',
        }
    },
    methods: {
        handleOnSignUp: function() {
            this.showEmailQuoteModal = true;
            this.actionType = 'sign_up';
        },
        handleOnConsultation: function () {
            this.showEmailQuoteModal = true;
            this.actionType = 'consultation';
        },
        handleEmailYourself: function () {
            this.showEmailQuoteModal = true;
            this.actionType = 'email_yourself';
        }
    },
    template: `
        <div class="my-5">
            <button @click="this.handleOnSignUp" class="bg-green mb-4 py-4 text-white font-quicksand font-bold rounded justify-center w-full">Sign up now</button>
            <p class="font-bold">Not ready to sign up yet?</p>
            <div class="mt-2">

                <div @click="handleOnConsultation" class="border-2 border-gray-300 rounded mb-2 p-2 flex flex-col items-center text-sm text-purple-700 text-bold flex-1 text-center cursor-pointer hover:bg-purple-100">
                    <div class="iconWrapper">
                        <svg class="w-8" viewBox="0 0 48 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Layer_3</title>
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="ss-icon-support" transform="translate(-101.100000, -6.400000)" fill="#4B286D" fill-rule="nonzero">
                                    <g id="Layer_1" transform="translate(1.100000, 6.800000)">
                                        <g id="Layer_3" transform="translate(100.100000, 0.100000)">
                                            <path d="M31,2 L4.1,2 C2.9,2 1.9,2.9 1.9,4.2 L1.9,23.2 C1.9,24.4 2.8,25.4 4.1,25.4 L7.3,25.4 C7.9,25.4 8.3,25.8 8.3,26.4 L8.3,32.1 L15.5,25.7 C15.7,25.6 15.9,25.5 16.1,25.5 L30.2,25.5 C31.9,25.5 33.2,24.2 33.2,22.5 L33.2,4.2 C33.1,2.9 32.1,2 31,2 Z M31,0 C33.3,0 35.2,1.9 35.2,4.2 L35.2,22.4 C35.2,25.1 33,27.4 30.2,27.4 L16.6,27.4 L8,35 C7.8,35.1 7.6,35.2 7.4,35.2 C7.3,35.2 7.1,35.2 7,35.1 C6.6,35 6.4,34.6 6.4,34.2 L6.4,27.3 L4.2,27.3 C1.9,27.3 0,25.4 0,23.1 L0,4.2 C0,1.9 1.9,0 4.2,0 C4.2,0 31,0 31,0 Z M43.6,12.6 C45.9,12.6 47.8,14.5 47.8,16.8 L47.8,34.9 C47.8,37.6 45.6,39.9 42.8,39.9 L41.4,39.9 L41.4,46.8 C41.4,47.2 41.2,47.6 40.8,47.7 C40.7,47.8 40.5,47.8 40.4,47.8 C40.1,47.8 39.9,47.7 39.7,47.5 L32.1,39.9 L15.2,39.9 C13.3,39.9 11.5,38.9 10.6,37.2 C10.3,36.7 10.5,36.1 11,35.8 C11.5,35.5 12.1,35.7 12.4,36.2 C12.9,37.2 14.1,37.8 15.3,37.8 L32.7,37.8 C33,37.8 33.2,37.9 33.4,38.1 L39.6,44.3 L39.6,38.9 C39.6,38.3 40,37.9 40.6,37.9 L43,37.9 C44.7,37.9 46,36.6 46,34.9 L46,16.8 C46,15.6 45.1,14.6 43.8,14.6 L37.5,14.6 C36.9,14.6 36.5,14.2 36.5,13.6 C36.5,13 36.9,12.6 37.5,12.6 L43.6,12.6 Z" id="SVGID_43_"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p class="text-xs">Schedule a free consultation with a SmartHome expert.</p>
                </div>

                <div @click="handleEmailYourself" class="border-2 border-gray-300 rounded p-2 flex flex-col items-center text-sm text-purple-700 text-bold flex-1 text-center cursor-pointer hover:bg-purple-100">
                    <div class="iconWrapper">
                        <svg class="w-8" viewBox="0 0 49 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Email yourself</title>
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="ss-icon-support" transform="translate(-439.100000, -162.400000)" fill="#4B286D" fill-rule="nonzero">
                                    <g id="Layer_1" transform="translate(1.100000, 6.800000)">
                                        <g id="Group" transform="translate(438.600000, 156.084574)">
                                            <path d="M2.1,36.0154261 L4,2.01542612 L20.4,3.41542612 L4.9,5.91542612 C4.6,6.01542612 4.4,6.11542612 4.3,6.31542612 C4.2,6.51542612 4.1,6.81542612 4.2,7.01542612 L9.5,36.7154261 L2.1,36.0154261 Z M22.9,37.9154261 L11.7,39.8154261 L6.1,7.81542612 L40.1,2.21542612 L44.3,26.0154261 C44.4,26.6154261 44.9,26.9154261 45.5,26.8154261 C46,26.7154261 46.4,26.2154261 46.3,25.6154261 L42,0.81542612 C41.9,0.31542612 41.4,-0.0845738801 40.8,0.0154261199 L28.3,2.11542612 L3.1,0.0154261199 C2.8,0.0154261199 2.6,0.11542612 2.4,0.21542612 C2.2,0.31542612 2,0.61542612 2,0.91542612 L0,36.9154261 C0,37.4154261 0.4,37.9154261 0.9,38.0154261 L9.6,38.9154261 L10,41.1154261 C10.1,41.6154261 10.5,41.9154261 11,41.9154261 C11.1,41.9154261 11.1,41.9154261 11.1,41.9154261 L23.2,39.9154261 C23.8,39.8154261 24.1,39.3154261 24,38.7154261 C24,38.1154261 23.4,37.8154261 22.9,37.9154261 Z" id="SVGID_7_"></path>
                                            <path d="M37,46.0154261 C32,46.0154261 28.1,42.0154261 28.1,37.1154261 C28.1,32.2154261 32.1,28.2154261 37,28.2154261 C41.9,28.2154261 45.9,32.2154261 45.9,37.1154261 C45.9,42.0154261 41.9,46.0154261 37,46.0154261 Z M37,25.9154261 C30.9,25.9154261 26,30.8154261 26,36.9154261 C26,43.0154261 30.9,47.9154261 37,47.9154261 C43.1,47.9154261 48,43.0154261 48,36.9154261 C48,30.8154261 43.1,25.9154261 37,25.9154261 Z" id="SVGID_9_"></path>
                                            <path d="M43.6,32.2154261 C43.2,31.8154261 42.6,31.8154261 42.2,32.2154261 L34.9,39.5154261 L31.6,36.2154261 C31.2,35.8154261 30.6,35.8154261 30.2,36.2154261 C29.8,36.6154261 29.8,37.2154261 30.2,37.6154261 L34.2,41.6154261 C34.4,41.8154261 34.6,41.9154261 34.9,41.9154261 C35.2,41.9154261 35.4,41.8154261 35.6,41.6154261 L43.6,33.6154261 C44.1,33.3154261 44.1,32.6154261 43.6,32.2154261 Z" id="SVGID_11_"></path>
                                            <path d="M36.4,8.31542612 L18.6,11.4154261 C18,11.5154261 17.7,12.0154261 17.8,12.6154261 C17.9,13.2154261 18.4,13.5154261 19,13.4154261 L36.8,10.3154261 C37.4,10.2154261 37.7,9.71542612 37.6,9.11542612 C37.4,8.51542612 36.9,8.21542612 36.4,8.31542612 Z" id="SVGID_13_"></path>
                                            <path d="M37.7,16.1154261 L19.9,19.2154261 C19.3,19.3154261 19,19.8154261 19.1,20.4154261 C19.2,21.0154261 19.7,21.3154261 20.3,21.2154261 L38.1,18.1154261 C38.7,18.0154261 39,17.5154261 38.9,16.9154261 C38.8,16.4154261 38.3,16.0154261 37.7,16.1154261 Z" id="SVGID_15_"></path>
                                            <path d="M29.3,25.8154261 L21.4,27.2154261 C20.8,27.3154261 20.5,27.8154261 20.6,28.4154261 C20.7,29.0154261 21.2,29.3154261 21.8,29.2154261 L29.7,27.8154261 C30.3,27.7154261 30.6,27.2154261 30.5,26.6154261 C30.4,26.1154261 29.9,25.7154261 29.3,25.8154261 Z" id="SVGID_17_"></path>
                                            <path d="M15.8,30.2154261 C15.2,30.3154261 14.7,29.9154261 14.6,29.4154261 C14.5,28.8154261 14.9,28.3154261 15.4,28.2154261 C16,28.1154261 16.5,28.5154261 16.6,29.0154261 C16.7,29.5154261 16.4,30.0154261 15.8,30.2154261 Z M15.1,26.2154261 C13.4,26.5154261 12.4,28.1154261 12.6,29.7154261 C12.9,31.4154261 14.5,32.4154261 16.1,32.2154261 C17.8,31.9154261 18.8,30.3154261 18.6,28.7154261 C18.3,27.0154261 16.7,25.9154261 15.1,26.2154261 Z" id="SVGID_19_"></path>
                                            <path d="M14.4,22.3154261 C13.8,22.4154261 13.3,22.0154261 13.2,21.5154261 C13.1,20.9154261 13.5,20.4154261 14,20.3154261 C14.6,20.2154261 15.1,20.6154261 15.2,21.1154261 C15.4,21.7154261 15,22.2154261 14.4,22.3154261 Z M13.8,18.4154261 C12.1,18.7154261 11.1,20.3154261 11.3,21.9154261 C11.6,23.6154261 13.2,24.6154261 14.8,24.4154261 C16.5,24.1154261 17.5,22.5154261 17.3,20.9154261 C16.9,19.2154261 15.4,18.1154261 13.8,18.4154261 Z" id="SVGID_21_"></path>
                                            <path d="M13.1,14.4154261 C12.5,14.5154261 12,14.1154261 11.9,13.6154261 C11.8,13.0154261 12.2,12.5154261 12.7,12.4154261 C13.3,12.3154261 13.8,12.7154261 13.9,13.2154261 C14,13.7154261 13.6,14.3154261 13.1,14.4154261 Z M12.3,10.4154261 C10.6,10.7154261 9.6,12.3154261 9.8,13.9154261 C10.1,15.6154261 11.7,16.6154261 13.3,16.4154261 C15,16.1154261 16,14.5154261 15.8,12.9154261 C15.6,11.3154261 14,10.1154261 12.3,10.4154261 Z" id="SVGID_23_"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p class="text-xs">Email yourself a copy of this quote.</p>
                </div>
                <!--<a class="border-0" @click="showEmailQuoteModal = true">
                    <div class="flex items-center justify-center space-x-2">
                        <svg style="background: #38a169;color: white;border-radius: 5px; padding: 5px;" class="w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                        </svg>
                        <h5>Email yourself this quote</h5>
                    </div>
                </a>-->
                <!--<a class="border-2" @click="showBookConsultationModal = true">
                    <div class="flex items-center justify-center space-x-2">
                        <svg style="background: #7868e6;color: white;border-radius: 5px; padding: 5px;" class="w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <h5>Book a free consultation call</h5>
                    </div>
                </a>
                <a class="border-0">
                    <div class="flex items-center justify-center space-x-2">
                        <svg style="background: #b34180;color: white;border-radius: 5px; padding: 5px;" class="w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                        </svg>
                        <h5>Book Now</h5>
                    </div>
                </a>-->
            </div>
            <email-quote-modal :action-type="this.actionType" v-if="showEmailQuoteModal" @close="showEmailQuoteModal = false"></email-quote-modal>
            <book-consultation-modal v-if="showBookConsultationModal" @close="showBookConsultationModal = false"></book-consultation-modal>
        </div>`
});

export default actionButtonsComponent;
