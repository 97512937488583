import Vue from "vue";
import Vuex from 'vuex';
import VueRouter from 'vue-router'

import "../scss/styles.scss";
import cameraComponent from "./components/camera";
import deviceComponent from "./components/device";
import panelComponent from "./components/panel";
import footerBarComponent from "./components/footer-bar";
import planHeader from "./components/planheader";

import homeComponent from "./components/home";
import plan1 from "./plans/p1.js";
import plan2 from "./plans/p2.js";
import plan3 from "./plans/p3.js";
import plan4 from "./plans/p4.js";

import actionButtonsComponent from "./components/action-buttons";
import notificationDiscountedBill from "./components/notification-discounted-bill";
import notificationBillCredit from "./components/notification-bill-credit";
import planSummary from "./components/plan-summary";
import planTermSelection from "./components/plan-term-selection";
import alwaysIncludedDevices from "./components/always-included-devices";
import installationOptions from "./components/installation-options";
import regionSelection from "./components/region-selection";

function createQueryString(data) {
    return Object.keys(data).map(key => {
        let val = data[key];
        if (val !== null && typeof val === 'object') val = createQueryString(val)
        return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
    }).join('&')
}

function calculatePayablePrice(devices, options) {
    const {
        selectedRegion,
        selectedPaymentOption,
        selectedTerm
    } = options;

    const totalPayablePrice = Object.values(devices).reduce((totalPrice, device) => {
        let devicePrice = device.device_price[selectedRegion];
        if (selectedPaymentOption === 'monthly') {
            devicePrice = devicePrice.monthly_price;
        } else if (selectedPaymentOption === 'onetime') {
            devicePrice = devicePrice.onetime_price;
        }
        devicePrice = devicePrice[selectedTerm];
        const payableQuantity = device.selectedQuantity - device.includedQuantity;
        return totalPrice + (devicePrice * payableQuantity);
    }, 0);
    return totalPayablePrice;
}

function calculateMonthlySubTotal(state) {
    const {cameras, home_automation, panels} = state.selectedPlan;
    const selectedRegion = state.selectedRegion.region_id;
    const selectedTerm = state.selectedTerm;
    const installationCharges = state.installationCharges;
    const selectedPaymentOption = state.selectedPaymentOption;
    const planBaseCost = parseFloat(state.plans[state.selectedPlan.plan_id].price[selectedRegion][selectedTerm]);

    const monthlyCost = Object.values({...cameras, ...home_automation, ...panels})
        .reduce((subTotal, device) => {
            const cost = device.device_price[selectedRegion].monthly_price[selectedTerm];
            return subTotal + cost * (device.selectedQuantity - device.includedQuantity);
        }, 0);

    if (selectedPaymentOption === 'monthly') {
        return (monthlyCost + planBaseCost).toFixed(2);
    }
    return planBaseCost.toFixed(2);
}

function calculateUpfrontSubtotal(state) {
    const {cameras, home_automation, panels} = state.selectedPlan;
    const selectedRegion = state.selectedRegion.region_id;
    const selectedTerm = state.selectedTerm;
    const installationCharges = state.installationCharges;
    const selectedPaymentOption = state.selectedPaymentOption;
    const upfrontCost = Object.values({...cameras, ...home_automation, ...panels})
        .reduce((subTotal, device) => {
            const cost = device.device_price[selectedRegion].onetime_price[selectedTerm];
            return subTotal + cost * (device.selectedQuantity - device.includedQuantity);
        }, 0);

    if (selectedPaymentOption === 'onetime') {
        return upfrontCost + installationCharges;
    }
    return installationCharges;
}

function calculateAdditionalDevicesSubtotal(state) {
    const selectedRegionId = state.selectedRegion.region_id;
    const selectedTerm = state.selectedTerm;
    return parseFloat(Object.values({
        ...state.selectedPlan.cameras,
        ...state.selectedPlan.home_automation,
        ...state.selectedPlan.panels
    }).reduce((totalCost, device) => {
        if (state.selectedPaymentOption === "monthly") {
            return totalCost + (device.selectedQuantity - device.includedQuantity) * device.device_price[selectedRegionId].monthly_price[selectedTerm];
        } else if (state.selectedPaymentOption === "onetime") {
            return totalCost + (device.selectedQuantity - device.includedQuantity) * device.device_price[selectedRegionId].onetime_price[selectedTerm];
        }

    }, 0).toFixed(2));
}

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function identifyRegion(regions) {
    let currentRegion = regions.filter(region => region.region_id === getParameterByName('region')).pop();
    if (!currentRegion) {
        currentRegion = regions[0];
    }
    console.log(currentRegion);
    return currentRegion;
}

const loadApplication = (config) => {
    Vue.use(Vuex);
    Vue.use(VueRouter);

    const {plans, regions, terms, texts} = config;
    const store = new Vuex.Store({
        state: {
            plans,
            regions,
            terms,
            texts,
            selectedPlan: {
                plan_id: 'p1',
                cameras: {},
                home_automation: {},
                sensors_detectors: {},
                panels: {},
                total_monthly_cost: 0,
                total_onetime_cost: 0,
                available_cameras: 0
            },
            selectedRegion: identifyRegion(regions),
            selectedTerm: Object.keys(terms)[0],
            selectedPaymentOption: 'monthly', // monthly or onetime,
            installationCharges: 0,
            selectedInstallationPlan: '',
            isEmailSending: false
        },
        mutations: {
            mutationAddCamera: function (state, cameraDetails) {
                Vue.set(state.selectedPlan.cameras, cameraDetails.id, {...cameraDetails, includedQuantity: 0});
                const selectedTerm = state.selectedTerm;
                let availableFreeCameras = plans[state.selectedPlan.plan_id].free_devices.security_cameras;

                const cameras = Object.values(state.selectedPlan.cameras)
                    .filter(camera => camera.selectedQuantity > 0)
                    .sort((a, b) =>
                        (a.device_price[state.selectedRegion.region_id].monthly_price[selectedTerm] < b.device_price[state.selectedRegion.region_id].monthly_price[selectedTerm]) ? 1 : -1)

                for (const camera of cameras) {
                    const selectedQuantity = camera.selectedQuantity;
                    let includedQuantity = (selectedQuantity >= availableFreeCameras) ? availableFreeCameras : availableFreeCameras - (availableFreeCameras - selectedQuantity);
                    includedQuantity = Math.max(0, includedQuantity);
                    availableFreeCameras = Math.max(0, availableFreeCameras - selectedQuantity);
                    console.log('availableFreeCameras', camera.id, availableFreeCameras);
                    Vue.set(state.selectedPlan.cameras[camera.id], 'includedQuantity', includedQuantity);
                }
            },
            mutationAddHomeAutomationDevice: async function (state, deviceDetails) {
                Vue.set(state.selectedPlan.home_automation, deviceDetails.id, {...deviceDetails, includedQuantity: 0});
                let availableFreeDevices = plans[state.selectedPlan.plan_id].free_devices.home_automation;
                const selectedTerm = state.selectedTerm;

                const devices = await Object.values(state.selectedPlan.home_automation)
                    .filter(device => device.selectedQuantity > 0)
                    .sort((a, b) =>
                        (a.device_price[state.selectedRegion.region_id].monthly_price[selectedTerm] < b.device_price[state.selectedRegion.region_id].monthly_price[selectedTerm]) ? 1 : -1);

                for (const device of devices) {
                    const selectedQuantity = device.selectedQuantity;
                    let includedQuantity = (selectedQuantity >= availableFreeDevices) ? availableFreeDevices : availableFreeDevices - (availableFreeDevices - selectedQuantity);
                    includedQuantity = Math.max(0, includedQuantity);
                    availableFreeDevices = Math.max(0, availableFreeDevices - selectedQuantity);
                    Vue.set(state.selectedPlan.home_automation[device.id], 'includedQuantity', includedQuantity);
                }
            },
            mutationAddPanel: function (state, deviceDetails) {
                Vue.set(state.selectedPlan.panels, deviceDetails.id, {...deviceDetails, includedQuantity: 0});
            },
            reCalculateTotalPrice: function (state) {
                Vue.set(state.selectedPlan, 'available_cameras', state.plans[state.selectedPlan.plan_id].free_devices.security_cameras);

                const selectedPlanId = state.selectedPlan.plan_id;
                const selectedRegion = state.selectedRegion.region_id;
                const selectedTerm = state.selectedTerm;
                const planBasePrice = state.plans[selectedPlanId].price[selectedRegion][selectedTerm];
                const selectedPaymentOption = state.selectedPaymentOption;
                const installationCharges = state.installationCharges;


                const options = {
                    selectedPlanId,
                    selectedRegion,
                    selectedPaymentOption,
                    selectedTerm
                };

                const camerasTotalPrice = calculatePayablePrice(state.selectedPlan.cameras, options);
                const homeAutomationPrice = calculatePayablePrice(state.selectedPlan.home_automation, options);
                const panelsPrice = calculatePayablePrice(state.selectedPlan.panels, options);

                console.log('homeAutomationPrice', homeAutomationPrice);
                const totalMonthlyPlanCost = (planBasePrice + camerasTotalPrice + homeAutomationPrice + panelsPrice).toFixed(2);
                const totalOneTimePlanCost = (installationCharges + camerasTotalPrice + homeAutomationPrice + panelsPrice).toFixed(2);
                if (state.selectedPaymentOption === 'monthly') {
                    Vue.set(state.selectedPlan, 'total_monthly_cost', totalMonthlyPlanCost);
                    Vue.set(state.selectedPlan, 'total_onetime_cost', totalOneTimePlanCost);
                } else if (state.selectedPaymentOption === 'onetime') {
                    Vue.set(state.selectedPlan, 'total_onetime_cost', totalOneTimePlanCost);
                }

            },
            changeSelectedPaymentMethod: function (state, paymentMethod) {
                Vue.set(state, 'selectedPaymentOption', paymentMethod);
            },
            changeSelectedRegion: function (state, regionId) {
                Vue.set(state, 'selectedRegion', ...state.regions.filter(region => region.region_id === regionId));
            },
            setSelectedPlan: function (state, planId) {
                console.log('Set Plan', planId);
                const selectedTerm = state.selectedTerm;

                Vue.set(state, 'selectedPlan', {
                    plan_id: planId,
                    cameras: {},
                    home_automation: {},
                    sensors_detectors: {},
                    panels: {},
                    total_monthly_cost: parseFloat(state.plans[planId].price[state.selectedRegion.region_id][selectedTerm]).toFixed(2),
                    total_onetime_cost: 0,
                    available_cameras: state.plans[planId].free_devices.security_cameras
                });

                Vue.set(state, 'selectedTerm', state.plans[planId].default_term);
                Vue.set(state, 'selectedPaymentOption', 'monthly');
                Vue.set(state, 'installationCharges', 0);
            },
            changeInstallationPlan: function (state, installType) {
                if (installType === 'self_install') {
                    Vue.set(state, 'installationCharges', 0);
                } else if (installType === 'professional_install') {
                    Vue.set(state, 'installationCharges', state.plans[state.selectedPlan.plan_id].installation_charges);
                }
                Vue.set(state, 'selectedInstallationPlan', installType);
            },
            changePlanTerm: function (state, term) {
                Vue.set(state, 'selectedTerm', term);
            },
            sendSignUpEmail: async function (state, emailData) {
                Vue.set(state, 'isEmailSending', true);
                const planDetails = state.plans[state.selectedPlan.plan_id];
                const selectedRegion = state.selectedRegion;
                const selectedTerm = state.selectedTerm;
                const selectedPaymentOption = state.selectedPaymentOption === 'monthly' ? 'monthly_price' : 'onetime_price';
                const includedDevices = await Object.values({...state.selectedPlan.cameras, ...state.selectedPlan.home_automation})
                    .filter(device => device.includedQuantity > 0)
                    .map((device) => {
                        return {
                            cameraId: device.id,
                            name: device.device_name,
                            price: 0,
                            selectedQuantity: device.selectedQuantity,
                            includedQuantity: device.includedQuantity
                        }
                    });
                const additionalDevices = await Object.values({
                    ...state.selectedPlan.cameras,
                    ...state.selectedPlan.home_automation,
                    ...state.selectedPlan.panels
                })
                    .filter(device => device.selectedQuantity - device.includedQuantity > 0)
                    .map((device) => {
                        let devicePrice = device.device_price[selectedRegion.region_id][selectedPaymentOption][selectedTerm];
                        devicePrice = devicePrice * (device.selectedQuantity - device.includedQuantity);
                        return {
                            cameraId: device.id,
                            name: device.device_name,
                            price: devicePrice.toFixed(2),
                            selectedQuantity: device.selectedQuantity,
                            includedQuantity: device.includedQuantity,
                            payableQuantity: device.selectedQuantity - device.includedQuantity,
                        }
                    });

                const emailBody = {
                    firstName: emailData.firstName,
                    lastName: emailData.lastName,
                    emailAddress: emailData.emailAddress,
                    plan: {
                        planName: planDetails.name,
                        planPrice: planDetails.price[selectedRegion.region_id][selectedTerm],
                        includedDevices: includedDevices,
                        additionalDevices: additionalDevices,
                        term: state.selectedTerm.replace('yr', ''),
                        paymentOption: state.selectedPaymentOption,
                        installationCharges: state.installationCharges,
                        discountedValue: (state.selectedPaymentOption === 'monthly') ? state.selectedPlan.total_onetime_cost : 0,
                        isMonthlyPaymentSelected: state.selectedPaymentOption === 'monthly',
                        isOnetimePaymentSelected: state.selectedPaymentOption === 'onetime',
                        monthlySubTotal: calculateMonthlySubTotal(state),
                        upfrontSubTotal: calculateUpfrontSubtotal(state),
                        selectedInstallationPlan: state.selectedInstallationPlan
                    },
                    action: emailData.actionType
                };

                fetch(JSENV.wp_json + '/customer_email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(emailBody)
                })
                    .then(res => res.json())
                    .then(res => {
                        Vue.set(state, 'isEmailSending', false);
                        const queryData = {
                            firstName: emailData.firstName,
                            lastName: emailData.lastName,
                            emailAddress: emailData.emailAddress,
                            planName: planDetails.name,
                            planPrice: planDetails.price[selectedRegion.region_id][selectedTerm],
                            ...includedDevices.reduce((allDevices, device) => {
                                return {
                                    ...allDevices,
                                    [device.name.replace(/[\s-]/g, '_') + '_included']: device.includedQuantity
                                };
                            }, {}),
                            ...additionalDevices.reduce((allDevices, device) => {
                                return {...allDevices, [device.name.replace(/[\s-]/g, '_')]: device.payableQuantity}
                            }, {}),
                            ...additionalDevices.reduce((allDevices, device) => {
                                return {...allDevices, [device.name.replace(/[\s-]/g, '_') + '_cost']: device.price}
                            }, {}),
                            term: state.selectedTerm.replace('yr', ''),
                            paymentOption: state.selectedPaymentOption,
                            installationCharges: state.installationCharges,
                            discountedValue: (state.selectedPaymentOption === 'monthly') ? calculateAdditionalDevicesSubtotal(state) : 0,
                            monthlySubTotal: calculateMonthlySubTotal(state),
                            upfrontSubTotal: calculateUpfrontSubtotal(state),
                            selectedInstallationPlan: state.selectedInstallationPlan
                        };

                        const queryParams = createQueryString(queryData);

                        switch (emailData.actionType) {
                            case 'sign_up': {
                                window.location.href = 'https://perksbysmartcell.ca/home-security-signup/?' + queryParams;
                                break;
                            }
                            case 'consultation': {
                                const {firstName, lastName, emailAddress} = emailData;
                                window.location.href = `https://calendly.com/telussmarthome/consultation?name=${firstName}%20${lastName}&email=${emailAddress}`;
                                break;
                            }
                            case 'email_yourself': {
                                window.location.href = 'https://perksbysmartcell.ca/adt-quote-confirmation/';
                                break
                            }
                        }

                    })
            }
        },
        actions: {
            actionAddCamerasToPlan: function ({commit}, cameraDetails) {
                commit('mutationAddCamera', cameraDetails);
            },
            actionAddAutomationDeviceToPlan: function ({commit}, deviceDetails) {
                commit('mutationAddHomeAutomationDevice', deviceDetails);
            },
            actionAddPanelsToPlan: function ({commit}, cameraDetails) {
                commit('mutationAddPanel', cameraDetails);
            },
            setIncludedCamera: function ({commit}, {planId, cameraId, selectedQuantity}) {
                commit('mutationAddCamera', {planId, cameraId, selectedQuantity})
            },
            reCalculateTotalPrice: function ({commit}) {
                commit('reCalculateTotalPrice');
            },
            changeSelectedPaymentMethod: function ({commit}, paymentMethod) {
                commit('changeSelectedPaymentMethod', paymentMethod);
            },
            changeSelectedRegion: function ({commit}, regionId) {
                commit('changeSelectedRegion', regionId);
            },
            actionSetSelectedPlan: function ({commit}, planId) {
                commit('setSelectedPlan', planId);
            },
            changeInstallationPlan: function ({commit}, installType) {
                commit('changeInstallationPlan', installType);
            },
            changePlanTerm: function ({commit}, term) {
                commit('changePlanTerm', term);
            },
            sendSignUpEmail: function ({commit}, emailData) {
                commit('sendSignUpEmail', emailData);
            }
        },
        getters: {
            getRegions: function (state) {
                return state.regions;
            },
            isCameraIncludedInHomeAutomation: function (state) {
                return function (cameraId) {
                    const cameras = state.selectedPlan.cameras;
                    if (Object.keys(cameras).includes(cameraId) && cameras[cameraId].selectedQuantity > 0) {
                        return state.selectedPlan.cameras[cameraId].includedQuantity;
                    }
                    return false;
                }
            },
            getTotalCost: function (state) {
                if (state.selectedPaymentOption === 'onetime') {
                    return state.plans[state.selectedPlan.plan_id].price[state.selectedRegion.region_id][state.selectedTerm].toFixed(2);
                }
                return state.selectedPlan.total_monthly_cost;
            },
            getTotalOneTimeCost: function (state) {
                return state.selectedPlan.total_onetime_cost;
            },
            getCurrentPlanName: function (state) {
                return state.plans[state.selectedPlan.plan_id].name;
            },
            getSelectedRegion: function (state) {
                return state.selectedRegion;
            },
            getSelectedPaymentOption: function (state) {
                return state.selectedPaymentOption;
            },
            getSelectedTerm: function (state) {
                return state.selectedTerm;
            },
            getIncludedCameras: function (state) {
                return Object.values({...state.selectedPlan.cameras, ...state.selectedPlan.home_automation}).filter(camera => camera.includedQuantity > 0)
            },
            getAdditionalDevices: function (state) {
                const devices = Object.values({
                    ...state.selectedPlan.cameras,
                    ...state.selectedPlan.home_automation,
                    ...state.selectedPlan.panels
                })
                    .filter(camera => camera.selectedQuantity - camera.includedQuantity > 0)
                    .map((camera) => {
                        return {
                            ...camera,
                            payableQuantity: camera.selectedQuantity - camera.includedQuantity
                        }
                    });
                console.log('getAdditionalDevices', devices);
                return devices;
            },
            getRemainingAvailableCamerasCount: function (state) {
                return state.selectedPlan.available_cameras
            },
            getPlanSubtotal: function (state) {
                const selectedTerm = state.selectedTerm;
                const planBasePrice = state.plans[state.selectedPlan.plan_id].price[state.selectedRegion.region_id][selectedTerm];
                const installationCost = state.installationCharges;
                const subTotal = Object.values({
                    ...state.selectedPlan.cameras,
                    ...state.selectedPlan.home_automation,
                    ...state.selectedPlan.panels
                }).reduce((calculatedValue, currentItem) => {
                    const payableQuantity = currentItem.selectedQuantity - currentItem.includedQuantity;
                    const devicePrice = state.selectedPaymentOption === "monthly" ?
                        currentItem.device_price[state.selectedRegion.region_id].monthly_price[selectedTerm] :
                        currentItem.device_price[state.selectedRegion.region_id].onetime_price[selectedTerm];
                    return calculatedValue + devicePrice * payableQuantity;
                }, 0).toFixed(2);

                if (state.selectedPaymentOption === "monthly") {
                    return (parseFloat(subTotal) + parseFloat(planBasePrice)).toFixed(2);
                } else {
                    return (parseFloat(subTotal) + parseFloat(installationCost)).toFixed(2);
                }
            },
            getCameraIncludedDeviceCount: function (state) {
                return Object.values(state.selectedPlan.cameras).reduce((totalIncluded, device) => {
                    return totalIncluded + device.includedQuantity;
                }, 0);
            },
            getHomeAutomationIncludedDeviceCount: function (state) {
                return Object.values(state.selectedPlan.home_automation).reduce((totalIncluded, device) => {
                    return totalIncluded + device.includedQuantity;
                }, 0);
            },
            isDeviceIncludedInHomeAutomation: function (state) {
                return function (deviceId) {
                    if (!state.selectedPlan.home_automation.hasOwnProperty(deviceId)) {
                        return false;
                    }

                    if (!state.selectedPlan.home_automation[deviceId].hasOwnProperty('includedQuantity')) {
                        return false;
                    }

                    return state.selectedPlan.home_automation[deviceId].includedQuantity;
                }
            },
            getSelectedInstallationPlan: function (state) {
                return state.installationCharges;
            },
            getAvailableTerms: function (state) {
                const selectedPlan = state.plans[state.selectedPlan.plan_id];
                const availableTermsInRegion = selectedPlan.price[state.selectedRegion.region_id];

                console.log(selectedPlan, Object.entries(availableTermsInRegion));

                return Object.entries(availableTermsInRegion).map((term) => {
                    return {
                        price: term[1],
                        ...state.terms[term[0]]
                    }
                });
                //return state.terms;
            },
            getAdditionalDevicesSubTotal: function (state) {
                return calculateAdditionalDevicesSubtotal(state);
            },
            getSelectedPlanDetails: function (state) {
                return state.plans[state.selectedPlan.plan_id];
            },
            getMonthlySubtotal: function (state) {
                return calculateMonthlySubTotal(state);
            },
            getUpfrontSubtotal: function (state) {
                return calculateUpfrontSubtotal(state);
            },
            getIsEmailSending: function (state) {
                return state.isEmailSending;
            },
            getSelfInstallationValue: function (state) {
                const selectedPlan = state.plans[state.selectedPlan.plan_id];
                return selectedPlan.self_installation;
            },
            getPlanDefaultInstallationOption: function (state) {
                const selectedPlan = state.plans[state.selectedPlan.plan_id];
                return selectedPlan.default_installation;
            },
            getApplicationTexts: function (state) {
                return state.texts;
            },
            getPlanTermNote: function (state) {
                return state.plans[state.selectedPlan.plan_id].term_note[state.selectedRegion.region_id];
            },
            getInstallationCharges: function (state) {
                return state.plans[state.selectedPlan.plan_id].installation_charges;
            },
            getSelectedInstallationPlanText: function (state) {
                return state.selectedInstallationPlan
            }

        }
    });

// Router
    const router = new VueRouter({
        routes: [
            {path: '/', component: homeComponent},
            {path: '/plan1', component: plan1},
            {path: '/plan2', component: plan2},
            {path: '/plan3', component: plan3},
            {path: '/plan4', component: plan4}
        ]
    });

    Vue.filter('fixed2', function (value) {
        if (!value) return '0.00';
        return value.toFixed(2);

    });

    new Vue({
        el: '#wptailvue-app',
        router: router,
        store: store,
        mounted: function () {
            console.log('VueJS Initiated');
        },
        components: {
            'plan-header': planHeader,
            'camera-component': cameraComponent,
            'footer-bar-component': footerBarComponent,
            'action-buttons-component': actionButtonsComponent,
            'device-component': deviceComponent,
            'panel-component': panelComponent,
            'notification-discounted-bill': notificationDiscountedBill,
            'notification-bill-credit': notificationBillCredit,
            'plan-summary': planSummary,
            'plan-term-selection': planTermSelection,
            'always-included-devices': alwaysIncludedDevices,
            'installation-options': installationOptions,
            'region-selection': regionSelection
        },
        template: `
        <div class="alignfull">
            <router-view></router-view>
        </div>
    `
    });
};

fetch(JSENV.config+'?v=' + new Date().valueOf())
    .then(res => res.json())
    .then(config => {
        loadApplication(config);
    });