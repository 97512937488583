import Vue from 'vue';
import {mapGetters} from 'vuex'

import {plans, regions} from "../../../plans";

const plan4 = Vue.component('plan4', {
    async mounted() {
        await this.$store.dispatch('actionSetSelectedPlan', "p4");
    },
    computed: {
        ...mapGetters([
            "getSelectedPaymentOption",
            "getRegions",
            "getSelectedRegion",
            "getTotalOneTimeCost",
            "getTotalCost",
            "getIncludedCameras",
            "getAdditionalDevices",
            "getRemainingAvailableCamerasCount",
            "getPlanSubtotal",
            "getCameraIncludedDeviceCount",
            "getHomeAutomationIncludedDeviceCount",
            "getSelectedInstallationPlan",
            "getAdditionalDevicesSubTotal",
            "getSelectedPlanDetails"
        ]),
        plan: function (){
            return this.getSelectedPlanDetails;
        },
        cameras: function () {
            return this.plan.devices.filter(device => device.type === 'security_cameras');
        },
        home_automation: function () {
            return this.plan.devices.filter(device => device.type === 'home_automation');
        },
        sensors_detectors: function () {
            return this.plan.devices.filter(device => device.type === 'sensors_detectors');
        },
        control_panels: function () {
            return this.plan.devices.filter(device => device.type === 'control_panels');
        }
    },
    methods: {
        onPaymentOptionChange: async function (e) {
            await this.$store.dispatch('changeSelectedPaymentMethod', e.target.value);
            await this.$store.dispatch('reCalculateTotalPrice');
        },
        onRegionChange: async function (e) {
            await this.$store.dispatch('changeSelectedRegion', e.target.value);
            await this.$store.dispatch('reCalculateTotalPrice');
        },
        onInstallationPlanChange: async function (e) {
            await this.$store.dispatch('changeInstallationPlan', e.target.value);
            await this.$store.dispatch('reCalculateTotalPrice');
        }
    },
    template: `
        <div class="inline-block px-5">
            <plan-header></plan-header>
            <region-selection></region-selection>
            
            <hr class="sectionDivider my-10 w-2/3">
            <always-included-devices></always-included-devices>
            <hr class="sectionDivider my-10 w-2/3">
            
            <div class="cameraSelection">
                <h2 class="text-3xl font-quicksand font-thin text-primary">Select your camera
                    ({{ plan.free_devices.security_cameras}} included in your plan)</h2>
                <h5>You can select more devices at an additional cost</h5>
                <h6 class="bg-gray-200 rounded p-2 inline-block md:mt-2">Included {{getCameraIncludedDeviceCount}}/1</h6>
                <div class="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-5 mt-10">
                    <camera-component v-for="device in cameras"
                                      v-bind:camera-details="device"
                                      v-bind:plan-id="plan.id"
                                      v-bind:max-included="plan.free_devices.security_cameras"
                    ></camera-component>
                </div>
            </div>

            <div class="additionalDevicesSelection mt-10">
                <h2 class="text-3xl font-quicksand font-thin text-primary">Select your devices
                    ({{ plan.free_devices.home_automation}} included in your plan)</h2>
                <h5>You can select more devices at an additional cost</h5>
                <h6 class="bg-gray-200 rounded p-2 inline-block md:mt-2">Included {{getHomeAutomationIncludedDeviceCount}}/{{ plan.free_devices.home_automation}}</h6>
                <div class="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-5 mt-10">
                    <device-component v-for="device in home_automation"
                                      v-bind:device-details="device"
                                      v-bind:plan-id="plan.id"
                                      v-bind:max-included="plan.free_devices.security_cameras"
                    ></device-component>
                </div>

                <div class="controlPanels mt-10">
                <h5>Sensors & Detectors</h5>
                    <div class="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-5 mt-10">
                        <device-component v-for="device in sensors_detectors"
                                          v-bind:device-details="device"
                                          v-bind:plan-id="plan.id"
                                          v-bind:max-included="plan.free_devices.security_cameras"
                        ></device-component>
                    </div>
                </div>
                
                <div class="controlPanels mt-10">
                    <div class="mb-5">
                        <h2 class="text-3xl font-quicksand font-thin text-primary">Select Additional Devices (optional)</h2>
                        <h5>You can add more devices at an additional cost</h5>
                    </div>
                    <h5>Control Panels</h5>
                    <div class="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-5 mt-10">
                        <panel-component v-for="device in control_panels"
                                          v-bind:device-details="device"
                                          v-bind:plan-id="plan.id"
                                          v-bind:max-included="plan.free_devices.control_panels"
                        ></panel-component>
                    </div>
                </div>
                
            </div>
            
            <hr class="sectionDivider my-10 w-2/3">
            <installation-options></installation-options>

            <hr class="sectionDivider my-16 w-2/3">
            <plan-term-selection></plan-term-selection>
            
            <hr class="sectionDivider my-16 w-2/3">
            <div class="paymentOptions font-quicksand md:w-3/4">
                <h2 class="text-3xl font-quicksand font-thin text-primary">Select your payment option for <span class="font-bold">additional
                    devices</span></h2>
                <p>Choose how you would like to pay for your additional devices below. Either spread out the cost for
                    your additional devices over your monthly bills or opt to pay for them upfront.</p>
                <p class="my-4 font-bold">Payment Options:</p>
                <div class="flex space-x-5" v-on:change="this.onPaymentOptionChange">
                    <label class="customRadioControl">
                        Monthly financing
                        <input type="radio" name="paymentplan-radio" value="monthly"
                               :checked="this.getSelectedPaymentOption === 'monthly'">
                        <span class="radioChecked"></span>
                    </label>
                    <label class="customRadioControl">
                        One-time payment
                        <input type="radio" name="paymentplan-radio" value="onetime"
                               :checked="this.getSelectedPaymentOption === 'onetime'">
                        <span class="radioChecked"></span>
                    </label>
                </div>
            </div>
            <hr class="sectionDivider my-16 w-2/3">
            <plan-summary :plan="plan"></plan-summary>
            <!--<div class="planSummary flex flex-col md:flex-row">
                <div class="md:w-6/12">
                    <div class="mb-5">
                        <h2 class="text-3xl font-quicksand font-thin text-primary mb-2">Summary</h2>
                        <h5 v-if="this.getSelectedPaymentOption === 'monthly' && getSelectedInstallationPlan > 0" class="font-quicksand font-bold text-primary">Monthly Plan</h5>
                    </div>
                    <h5>Due Each month</h5>
                    <div class="flex justify-between">
                        <div>{{ this.plan.name }}</div>
                        <div>
                            {{ this.getSelectedRegion.region_currency }}{{ this.plan.price[this.getSelectedRegion.region_id] }}/month
                        </div>
                    </div>
                    <div class="flex flex-col justify-between mt-5 font-quicksand">
                        <h5 class="font-bold">Included Devices</h5>
                        <div class="flex justify-between" v-for="includedCamera in this.getIncludedCameras">
                            <div>{{includedCamera.includedQuantity }} {{includedCamera.device_name}}</div>
                            <div>Included</div>
                        </div>
                    </div>
                    <div class="flex flex-col justify-between font-quicksand" v-if="getSelectedPaymentOption === 'monthly'">
                        <hr class="sectionDivider my-6 w-2/3">
                        <h5 class="font-bold">Additional Devices</h5>
                        <div class="flex justify-between" v-for="additionalDevice in this.getAdditionalDevices">
                            <div>{{additionalDevice.payableQuantity}} {{additionalDevice.device_name}}</div>
                            <div>{{ getSelectedRegion.region_currency }}
                                <span v-if="getSelectedPaymentOption === 'monthly'">{{additionalDevice.device_price[getSelectedRegion.region_id].monthly_price * additionalDevice.payableQuantity }}</span>
                                <span v-if="getSelectedPaymentOption === 'onetime'">{{additionalDevice.device_price[getSelectedRegion.region_id].onetime_price}}</span>
                            </div>
                        </div>
                        <div class="flex justify-between mt-5 font-semibold">
                            <p>Subtotal (before taxes)</p>
                            <p>{{ getSelectedRegion.region_currency }}{{ getPlanSubtotal }}</p>
                        </div>
                    </div>
                    
                    &lt;!&ndash;<div class="flex flex-col justify-between mt-5 font-quicksand" v-if="getSelectedInstallationPlan > 0">
                        <hr class="sectionDivider my-6 w-2/3">
                        <h5 class="font-bold">Installation</h5>
                        <div class="flex justify-between" >
                            <div>Professional Install</div>
                            <div>{{ getSelectedRegion.region_currency }} {{getSelectedInstallationPlan}}</div>
                        </div>
                    </div>&ndash;&gt;
                    
                    <div class="flex flex-col justify-between font-quicksand" v-if="getSelectedPaymentOption === 'onetime' || getSelectedInstallationPlan > 0 ">
                        <hr class="sectionDivider my-6 w-2/3">
                        <h5 class="font-bold">Upfront Cost</h5>
                        
                        <div class="flex flex-col justify-between mt-5 font-quicksand my-5" v-if="getSelectedInstallationPlan > 0">
                            <h5 class="font-bold">Installation</h5>
                            <div class="flex justify-between" >
                                <div>Professional Install</div>
                                <div>{{ getSelectedRegion.region_currency }} {{getSelectedInstallationPlan}}</div>
                            </div>
                        </div>
                        <div v-if="getSelectedPaymentOption === 'onetime'">
                            <h6>Additional Device(s)</h6>
                            <div class="flex justify-between" v-for="additionalDevice in this.getAdditionalDevices">
                                <div>{{additionalDevice.payableQuantity}} {{additionalDevice.device_name}}</div>
                                <div>{{ getSelectedRegion.region_currency }}
                                    <span>{{additionalDevice.device_price[getSelectedRegion.region_id].onetime_price * additionalDevice.payableQuantity}}</span>
                                </div>
                            </div>
                            <div class="flex justify-between mt-5 font-semibold">
                                <p>Subtotal (before taxes)</p>
                                <p>{{ getSelectedRegion.region_currency }}{{ getPlanSubtotal }}</p>
                            </div>
                        </div>
                        
                    </div>
                    <hr class="sectionDivider my-2 w-2/3">
                    &lt;!&ndash;<div class="flex flex-col">
                        <div class="flex justify-between">
                            <p>Subtotal (before taxes)</p>
                            <p>{{ getSelectedRegion.region_currency }}{{ getPlanSubtotal }}</p>
                        </div>
                    </div>&ndash;&gt;
                    
                    <div class="md:w-3/4 m-auto mt-5">
                        <notification-discounted-bill :plan="this.plan"></notification-discounted-bill>
                        <notification-bill-credit :plan="this.plan"></notification-bill-credit>
                        <action-buttons-component></action-buttons-component>
                    </div>
                    
                </div>
                &lt;!&ndash;<div class="md:flex-1">
                    <div class="shadow-lg font-quicksand md:w-2/3 m-auto p-5">
                        <table class="SummaryTable" border="0">
                            <thead class="border-b-2">
                                <tr>
                                    <th class="font-thin text-2xl">Summary</th>
                                    <td class="text-sm">Monthly Bill</td>
                                    <td class="text-sm">First Bill</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-sm">
                                    <th>Plan</th>
                                    <td>{{ this.getSelectedRegion.region_currency }}{{ this.plan.price[this.getSelectedRegion.region_id] }}/month</td>
                                    <td>{{ this.getSelectedRegion.region_currency }}{{ this.plan.price[this.getSelectedRegion.region_id] }}/month</td>
                                </tr>
                                <tr class="text-sm">
                                    <th>Additional Devices</th>
                                    <td>{{ this.getSelectedRegion.region_currency }}{{ this.getTotalOneTimeCost}} </td>
                                    <td>{{ this.getSelectedRegion.region_currency }}{{ this.getTotalOneTimeCost}} </td>
                                </tr>
                                <tr class="text-sm">
                                    <th>Subtotal</th>
                                    <td>{{ this.getSelectedRegion.region_currency }}{{ this.getTotalCost}} </td>
                                    <td>{{ this.getSelectedRegion.region_currency }}0 </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>&ndash;&gt;
            </div>-->
            <hr class="sectionDivider my-16 w-2/3">
            <footer-bar-component :plan="this.plan"></footer-bar-component>
        </div>
    `
});

export default plan4;


