import Vue from "vue";
import { mapGetters } from 'vuex'

const deviceComponent = Vue.component('device-component', {
    props: [
        'deviceDetails'
    ],
    data() {
        return {
            deviceImage: JSENV.assets_url + this.deviceDetails.device_image,
            selectedQuantity: 0
        }
    },
    mounted: function () {
        console.log('device component mounted');
    },
    computed: {
        ...mapGetters([
            'isIncludedInPlan',
            'getSelectedRegion',
            'getSelectedPaymentOption',
            'getSelectedTerm'
        ]),
        isIncludedInHomeAutomation: function () {
            return this.$store.getters.isDeviceIncludedInHomeAutomation(this.deviceDetails.id);
        }
    },
    methods: {
        onQuantityChange: async function (e) {
            await this.$store.dispatch('actionAddAutomationDeviceToPlan',{
                ...this.deviceDetails,
                selectedQuantity: parseInt(e.target.value)
            });
            await this.$store.dispatch('reCalculateTotalPrice');
        }
    },
    template: `
        <div class="bg-white p-2 shadow-lg rounded relative md:w-4/3 md:min-w-card font-quicksand border-2 border-transparent" :class="{'border-2 border-green-500' : isIncludedInHomeAutomation}">
            <div v-if="isIncludedInHomeAutomation" class="absolute text-sm bg-gray-500 text-white py-1 px-4 top-0 right-0">{{ isIncludedInHomeAutomation }} Included</div>
            <div class="flex flex-col items-center h-full space-y-5">
                <div class="camera-image flex-1">
                    <img :src="deviceImage">
                </div>
                <div class="camera-description w-full flex-1">
                    <p class="text-xs font-bold">{{ this.deviceDetails.device_name }}</p>
                    <div class="text-xs font-bold text-purple-500">
                        <p v-if="this.getSelectedPaymentOption === 'monthly'">{{ '$' }}{{ this.deviceDetails.device_price[this.getSelectedRegion.region_id].monthly_price[getSelectedTerm] }}/month</p>
                        <p v-if="this.getSelectedPaymentOption === 'onetime'">{{ '$' }}{{ this.deviceDetails.device_price[this.getSelectedRegion.region_id].onetime_price[getSelectedTerm] }} one time payment</p>
                    </div>
                </div>
                <div class="camera-dropdown-control w-full mt-auto">
                    <select class="w-full border rounded focus:shadow-lg h-12" v-on:change="this.onQuantityChange">
                        <option v-for="(n, index) in this.deviceDetails.device_quantity + 1 ">{{ index }}</option>
                    </select>
                </div>
            </div>
        </div>
    `
});

export default deviceComponent;
