import Vue from "vue";
import {mapGetters} from "vuex";

const planSummary = Vue.component('plan-summary', {
    props: [
        'plan'
    ],
    computed: {
        ...mapGetters([
            'getSelectedPaymentOption',
            'getSelectedInstallationPlan',
            'getSelectedRegion',
            'getIncludedCameras',
            'getAdditionalDevices',
            'getPlanSubtotal',
            'getSelectedTerm',
            'getMonthlySubtotal',
            'getUpfrontSubtotal',
            'getSelectedInstallationPlanText'
        ])
    },
    template: `
        <div class="planSummary flex flex-col md:flex-row">
            <div class="md:w-6/12">
                <div class="mb-5">
                    <h2 class="text-3xl font-quicksand font-thin text-primary mb-2">Summary</h2>
                    <h5 v-if="this.getSelectedPaymentOption === 'monthly' && getSelectedInstallationPlan > 0"
                        class="font-quicksand font-bold text-primary">Monthly Plan</h5>
                </div>
                <h5 class="font-bold mb-5">Due Each month</h5>
                <div class="flex justify-between">
                    <div>{{ this.plan.name }}</div>
                    <div>
                        {{ this.getSelectedRegion.region_currency }}{{ this.plan.price[this.getSelectedRegion.region_id][getSelectedTerm] }}
                        /month
                    </div>
                </div>
                <div class="flex flex-col justify-between mt-5 mb-2 font-quicksand" v-if="this.getIncludedCameras.length > 0">
                    <h5 class="font-bold">Included Devices</h5>
                    <div class="flex justify-between" v-for="includedCamera in this.getIncludedCameras">
                        <div>{{includedCamera.includedQuantity }}
                            <span class="multiple" v-if="includedCamera.includedQuantity > '1'">{{includedCamera.device_name + 's'}}</span>
                            <span class="single" v-else>{{includedCamera.device_name}}</span>
                        </div>
                        <div>{{ getSelectedRegion.region_currency }}0/month</div>
                    </div>
                </div>
                <div class="flex flex-col justify-between font-quicksand" v-if="this.getAdditionalDevices.length > 0 && getSelectedPaymentOption === 'monthly'">
                    <h5 class="font-bold">Additional Devices</h5>
                    <div class="flex justify-between" v-for="additionalDevice in this.getAdditionalDevices">
                        <div>{{additionalDevice.payableQuantity}}
                            <span class="multiple" v-if="additionalDevice.payableQuantity > '1'">{{additionalDevice.device_name + 's'}}</span>
                            <span class="single" v-else>{{additionalDevice.device_name}}</span>
                        </div>
                        <div>{{ getSelectedRegion.region_currency }}
                            <span v-if="getSelectedPaymentOption === 'monthly'">{{additionalDevice.device_price[getSelectedRegion.region_id].monthly_price[getSelectedTerm] * additionalDevice.payableQuantity | fixed2 }}</span>
                            <span v-if="getSelectedPaymentOption === 'onetime'">{{additionalDevice.device_price[getSelectedRegion.region_id].onetime_price[getSelectedTerm]}}</span>
                        </div>
                    </div>
                </div>

                <div class="flex justify-between mt-5 font-semibold">
                    <p>Due monthly subtotal (plus taxes)</p>
                    <p>{{ getSelectedRegion.region_currency }}{{ getMonthlySubtotal }}/month</p>
                </div>

                <div class="mt-5">
                    <!--<notification-discounted-bill :plan="this.plan"></notification-discounted-bill>-->
                    <notification-bill-credit :plan="this.plan"></notification-bill-credit>
                </div>

                <div class="flex flex-col justify-between font-quicksand">
                    <hr class="sectionDivider my-6 w-2/3">
                    <h5 class="font-bold mb-5">Due upfront</h5>

                    <div class="flex flex-col justify-between mt-5 font-quicksand my-5">
                        <h5 class="font-bold">Installation</h5>
                        <div class="flex justify-between">
                            <div v-if="getSelectedInstallationPlanText === 'self_install' ">Self Install</div>
                            <div v-if="getSelectedInstallationPlanText ===  'professional_install' ">Professional Install</div>
                            <div>{{ getSelectedRegion.region_currency }}{{getSelectedInstallationPlan}}</div>
                        </div>
                    </div>
                    <div v-if="this.getAdditionalDevices.length > 0 && getSelectedPaymentOption === 'onetime'">
                        <h6 class="font-bold">Additional Device(s)</h6>
                        <div class="flex justify-between" v-for="additionalDevice in this.getAdditionalDevices">
                            <div>{{additionalDevice.payableQuantity}}
                                <span class="multiple" v-if="additionalDevice.payableQuantity > '1'">{{additionalDevice.device_name + 's'}}</span>
                                <span class="single" v-else>{{additionalDevice.device_name}}</span>
                            </div>
                            <div>{{ getSelectedRegion.region_currency }}
                                <span>{{additionalDevice.device_price[getSelectedRegion.region_id].onetime_price[getSelectedTerm] * additionalDevice.payableQuantity}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between mt-5 font-semibold">
                        <p>Due upfront subtotal (plus taxes)</p>
                        <p>{{ getSelectedRegion.region_currency }}{{ getUpfrontSubtotal }}</p>
                    </div>

                </div>
                <hr class="sectionDivider my-2 w-2/3">

                <div class="mt-5">
                    <action-buttons-component></action-buttons-component>
                </div>

            </div>
        </div>`
});

export default planSummary;
