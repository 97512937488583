import Vue from "vue";
import {mapGetters} from "vuex";

const planHeader = Vue.component('plan-header', {
    computed: {
        ...mapGetters(['getCurrentPlanName'])
    },
    template: `
        <div class="planHeader font-quicksand mb-8">
            <h2 class="text-3xl text-primary text-primary font-quicksand">Configure your {{ getCurrentPlanName }} plan</h2>
            <h5 class="font-quicksand">Choose from different configurations to create a plan that works for you.</h5>
            <hr class="sectionDivider my-10 w-2/3">
        </div>
    `
});

export default planHeader;
