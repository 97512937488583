import Vue from "vue";
import {mapGetters} from 'vuex'

const footerBarComponent = Vue.component('footer-bar-component', {
    props: [
        'plan'
    ],
    computed: {
        ...mapGetters([
            "getSelectedPaymentOption",
            "getRegions",
            "getSelectedRegion",
            "getTotalOneTimeCost",
            "getTotalCost",
        ])
    },
    template: `
        <div class="fixed w-full bottom-0 py-2 left-0 md:flex md:space-x-2" style="background-color:rgb(247, 247, 248); display:none;">
            <div class="flex md:flex-2 px-2">
                <div class="hidden md:block md:flex-1">
                    <p class="font-bold">{{ plan.name }}</p>
                    <p class="font-quicksand">Our recommendation</p>
                </div>
                <div class="flex justify-between w-full md:flex-col md:flex-1 md:items-end md:pr-1 md:border-r-2">
                    <!--<p class="font-bold">Due first bill</p>
                    <p>{{ this.getSelectedRegion.region_currency }}{{ this.getTotalOneTimeCost }}</p>-->
                </div>
            </div>
            <div class="flex flex-col md:flex-1 px-2 md:flex-row">
                <div class="flex justify-between w-full md:flex-col md:flex-1 md:items-start">
                    <p class="font-bold">Due Monthly</p>
                    <p>{{ this.getSelectedRegion.region_currency }}{{ this.getTotalCost }}</p>
                </div>
                <div class="flex">
                    <button class="w-full md:w-auto border-1 rounded bg-green-600 text-white font-bold">View summary</button>
                </div>
            </div>
        </div>
    `
});

export default footerBarComponent;
