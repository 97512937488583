import Vue from "vue";
import {plans} from "../../../plans";

const homeComponent = Vue.component('home', {
    computed: {
      allPlans: function () {
            return Object.values(plans);
      }
    },
    template:
    `<div>
        <div class="flex flex-col md:flex-row font-quicksand justify-center md:space-x-2">
            <div v-for="plan in allPlans" class="flex flex-col rounded shadow-lg py-4 px-4 border-1 md:max-w-planCard mx-5 md:mx-0">
                <div>
                    <h4 class="font-quicksand">{{ plan.name }}</h4>
                    <p class="text-sm">{{ plan.subTitle }}</p>
                </div>
                <div class="mt-10">
                    <p class="text-sm">Price:</p>
                    <h1><sup class="text-md">$</sup> {{plan.displayPrice }}<span class="text-sm"> /mon</span></h1>
                </div>
                <div class="my-5">
                    <p class="font-bold text-sm font-quicksand">What's included:</p>
                    <ul class="text-xs">
                        <li v-for="feature in plan.features">{{feature}}</li>
                    </ul>
                </div>
                <div class="flex mt-auto w-full bg-green-700 text-white text-center">
                    <router-link class="text-white p-5 w-full h-full hover:text-white" :to="plan.route">Select Plan</router-link>
                </div>
            </div>
        </div>
    </div>`
});

export default homeComponent;