import Vue from "vue";
import {mapGetters} from "vuex";

const planTermSelection = Vue.component('plan-term-selection', {
    computed: {
      ...mapGetters([
          'getAvailableTerms',
          'getSelectedPlanDetails',
          'getSelectedRegion',
          'getSelectedTerm',
          'getPlanTermNote'
      ])
    },
    methods: {
      handleOnPlanTermChange: async function (e) {
          await this.$store.dispatch('changePlanTerm', e.target.value);
          await this.$store.dispatch('reCalculateTotalPrice');
      }
    },
    template: `
    <div class="planTermSelection">
        <h2 class="text-3xl font-quicksand font-thin text-primary">Choose your term length</h2>
        <div class="flex flex-col md:flex-row md:space-x-2" v-on:change="this.handleOnPlanTermChange">
            <div v-for="term in getAvailableTerms" class="flex flex-col md:w-1/4 h-40 p-5 border-2 rounded space-y-2 mt-5">
                <div style="flex-basis: 25%">
                    <label class="customRadioControl">
                        {{term.title}}
                        <input type="radio" name="plan-select-radio" :value="term.id" :checked="getSelectedTerm === term.id">
                        <span class="radioChecked"></span>
                    </label>
                    <div class="text-4xl font-thin pl-8">
                        <p style="width: max-content" class="font-quicksand text-xs bg-purple-500 py-1 px-2 text-white font-bold rounded">Limited time offer</p>
                        <sup>{{getSelectedRegion.region_currency}}</sup>
                        <span class="font-bold">{{getSelectedPlanDetails.price[getSelectedRegion.region_id][term.id]}}</span>
                        <sub>/month</sub>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="getPlanTermNote" class="cdAlert md:w-2/4 flex border-2 rounded bg-info p-2 text-sm font-quicksand my-2 space-x-3">
            {{getPlanTermNote}}
        </div>
    </div>
   `
});

export default planTermSelection;
