import Vue from 'vue';
import {mapGetters} from 'vuex'

const plan1 = Vue.component('plan1', {
    async mounted() {
        console.log('mounted P1');
        await this.$store.dispatch('actionSetSelectedPlan', "p1");
    },
    computed: {
        ...mapGetters([
            "getSelectedPaymentOption",
            "getRegions",
            "getSelectedRegion",
            "getTotalOneTimeCost",
            "getTotalCost",
            "getIncludedCameras",
            "getAdditionalDevices",
            "getRemainingAvailableCamerasCount",
            "getPlanSubtotal",
            "getCameraIncludedDeviceCount",
            "getAdditionalDevicesSubTotal",
            "getSelectedPlanDetails",
            "getSelfInstallationValue",
            "getApplicationTexts",
            "getPlanTermNote"
        ]),
        plan: function (){
            return this.getSelectedPlanDetails;
        },
        cameras: function () {
            return this.plan.devices.filter(device => device.type === 'security_cameras');
        },
        texts: function () {
            return this.getApplicationTexts;
        }
    },
    methods: {
        onPaymentOptionChange: async function (e) {
            await this.$store.dispatch('changeSelectedPaymentMethod', e.target.value);
            await this.$store.dispatch('reCalculateTotalPrice');
        },
        onRegionChange: async function (e) {
            await this.$store.dispatch('changeSelectedRegion', e.target.value);
            await this.$store.dispatch('reCalculateTotalPrice');
        }
    },
    template: `
        <div class="inline-block px-5">
            <plan-header></plan-header>
            <region-selection></region-selection>
            <hr class="sectionDivider my-10 w-2/3">
            <div class="cameraSelection">
                <h2 class="text-3xl font-quicksand font-thin text-primary">Select your camera
                    ({{ plan.free_devices.security_cameras}} included in your plan)</h2>
                <h5>You can select more devices at an additional cost</h5>
                <h6 class="bg-gray-200 rounded p-2 inline-block md:mt-2">Included {{getCameraIncludedDeviceCount}}/1</h6>
                <div class="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-5 mt-10">
                    <camera-component v-for="device in cameras"
                                      v-bind:camera-details="device"
                                      v-bind:plan-id="plan.id"
                                      v-bind:max-included="plan.free_devices.security_cameras"
                    ></camera-component>
                </div>
            </div>
            <hr class="sectionDivider my-10 w-2/3">
            <div v-if="getSelfInstallationValue" class="planInstallation">
                <h2 class="text-3xl font-quicksand font-thin text-primary">Installation</h2>
                <div class="md:flex mt-5">
                    <div style="flex-basis: 25%">
                        <h3>Self Install</h3>
                        <p class="text-4xl font-thin"><sup>{{ this.getSelectedRegion.region_currency }}</sup>0</p>
                    </div>
                    <div class="space-y-5 font-quicksand" style="flex-basis: 50%">
                        <h6>{{ texts.self_install_title }}</h6>
                        <p>{{ texts.self_install_description}}</p>
                    </div>
                </div>
                <div v-if="getPlanTermNote" class="cdAlert md:w-2/4 flex border-2 rounded border-green-300 p-2 bg-green-100 text-sm font-quicksand my-2 space-x-3">
                    {{getPlanTermNote}}
                </div>
                <hr class="sectionDivider my-16 w-2/3">
            </div>
            <div class="paymentOptions font-quicksand md:w-3/4">
                <h2 class="text-3xl font-quicksand font-thin text-primary">Select your payment option for <span class="font-bold">additional
                    devices</span></h2>
                <p>Choose how you would like to pay for your additional devices below. Either spread out the cost for
                    your additional devices over your monthly bills or opt to pay for them upfront.</p>
                <p class="my-4 font-bold">Payment Options:</p>
                <div class="flex space-x-5" v-on:change="this.onPaymentOptionChange">
                    <label class="customRadioControl">
                        Monthly financing
                        <input type="radio" name="paymentplan-radio" value="monthly"
                               :checked="this.getSelectedPaymentOption === 'monthly'">
                        <span class="radioChecked"></span>
                    </label>
                    <label class="customRadioControl">
                        One-time payment
                        <input type="radio" name="paymentplan-radio" value="onetime"
                               :checked="this.getSelectedPaymentOption === 'onetime'">
                        <span class="radioChecked"></span>
                    </label>
                </div>
            </div>
            <hr class="sectionDivider my-16 w-2/3">
            <plan-summary :plan="plan"></plan-summary>
            <hr class="sectionDivider my-16 w-2/3">
            <footer-bar-component :plan="this.plan"></footer-bar-component>
        </div>
    `
});

export default plan1;


